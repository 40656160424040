<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
          <Breadcrumb
            :home="home"
            :model="items"
            class="p-menuitem-text p-p-1"
          />
        </template>

        <template v-slot:right>
          <div class="p-inputgroup">
            <InputText
              v-model.trim="keyword"
              required="true"
              :class="{ 'p-invalid': submitted && !keyword }"
              placeholder="Search"
            />
            <Button
              icon="pi pi-search "
              class="p-button-primary p-mr-1"
              @click="loadSearchData"
            />
          </div>
          <Button
            icon="pi pi-plus"
            class="p-mr-2 p-button-success"
            @click="openDialog"
          />
        </template>
      </Toolbar>
      <div class="p-mt-2">
        <DataTable
          :value="lists"
          :lazy="true"
          :paginator="checkPagination"
          :rows="limit"
          :totalRecords="totalRecords"
          :scrollable="true"
          :modal="true"
          @page="onPage($event)"
          class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
        >
          <template #empty>
            <div class="p-text-center p-p-3">No records found</div>
          </template>
          <Column field="corporate_name" header="Corporate"></Column>
          <Column field="title" header="Title"></Column>
          <Column field="contact" header="Contact"></Column>
          <Column
            field="email"
            header="Email"
            headerStyle="width: 200px"
          ></Column>
          <Column field="created_date" header="Date"></Column>
          <Column field="emp_no" header="Emp No">
            <template #body="slotProps">
              {{ slotProps.data.emp_no == 0 ? "Yes" : "No" }}
            </template>
          </Column>
          <Column field="po_no" header="Po No">
            <template #body="slotProps">
              {{ slotProps.data.po_no == 0 ? "Yes" : "No" }}
            </template>
          </Column>
          <Column field="requisition_form" header="Req Form">
            <template #body="slotProps">
              {{ slotProps.data.requisition_form == 0 ? "Yes" : "No" }}
            </template>
          </Column>
          <Column
            field="schedule_name"
            header="Price List"
            headerStyle="width: 150px"
          ></Column>
          <Column field="account_type" header="Account Type"></Column>
          <Column field="status" header="Status"></Column>
          <Column :exportable="false" header="Action">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-primary p-mr-2"
                @click="editIem(slotProps.data)"
              />
              <Button
                v-if="slotProps.data.status == 'Active'"
                icon="pi pi-eye-slash"
                class="p-button-rounded p-button-danger"
                @click="confirmChangeStatus(slotProps.data, 'Inactive')"
              />
              <Button
                v-else-if="slotProps.data.status == 'Inactive'"
                icon="pi pi-eye"
                class="p-button-rounded p-button-warning"
                @click="confirmChangeStatus(slotProps.data, 'Active')"
              />
            </template>
          </Column>
        </DataTable>
      </div>

      <Dialog
        v-model:visible="productDialog"
        :style="{ width: '70vw' }"
        :maximizable="true"
        position="top"
        class="p-fluid"
      >
        <template #header>
          <h4 class="p-dialog-titlebar p-dialog-titlebar-icon">
            {{ dialogTitle }}
          </h4>
        </template>
        <div class="p-field">
          <label for="category">Corporate Head:</label>
          <Dropdown
            v-model="corporateData.corpHead"
            :options="allCorp"
            :filter="true"
            optionLabel="corporate_name"
          />
        </div>
        <div class="p-field">
          <label for="title">Title:</label>
          <InputText
            id="title"
            v-model.trim="corporateData.title"
            required="true"
            autofocus
            :class="{ 'p-invalid': submitted && !corporateData.title }"
          />
          <small class="p-invalid" v-if="submitted && !corporateData.title"
            >Title is required.</small
          >
        </div>
        <div class="p-field">
          <label for="address">Address:</label>
          <InputText id="address" v-model.trim="corporateData.address" />
        </div>
        <div class="p-field">
          <label for="address">Contact:</label>
          <InputText id="contact" v-model.trim="corporateData.contact" />
        </div>
        <div class="p-field">
          <label for="email">Email:</label>
          <InputText id="email" v-model.trim="corporateData.email" />
        </div>
        <div class="p-field">
          <label for="corporatelimit">Limit</label>
          <InputNumber id="corporatelimit" v-model="corporateData.corporateLimit"  mode="currency" currency="USD" locale="en-US" />
        </div>
        <div class="p-field">
          <label for="description">Description:</label>
          <InputText
            id="description"
            v-model.trim="corporateData.description"
          />
        </div>
        <div class="p-field">
          <label for="description">Fax:</label>
          <InputText id="fax" v-model.trim="corporateData.fax" />
        </div>
        <div class="p-field">
          <label for="accounttype">Account Type:</label>
          <Dropdown
            v-model="corporateData.accounttype"
            :options="accountTypes"
            optionLabel="key"
            optionValue="key"
          />
        </div>
        <div class="p-field">
          <label for="schedule">Schedule:</label>
          <Dropdown
            v-model="corporateData.schedule"
            :options="allSchedule"
            :filter="true"
            optionLabel="schedule_name"
          />
        </div>
        <div class="p-field">
          <div class="p-grid">
            <div class="p-col-4">
              <p>Want Employee No ?</p>
            </div>
            <div class="p-col-4">
              <div class="p-field-radiobutton">
                <RadioButton
                  value="0"
                  v-model="corporateData.empNo"
                />
                <label>Yes</label>
              </div>
            </div>
            <div class="p-col-4">
              <div class="p-field-radiobutton">
                <RadioButton
                  value="1"
                  v-model="corporateData.empNo"
                />
                <label>No</label>
              </div>
            </div>
          </div>
        </div>
        <div class="p-field">
          <div class="p-grid">
            <div class="p-col-4">
              <p>Want PO No ?</p>
            </div>
            <div class="p-col-4">
              <div class="p-field-radiobutton">
                <RadioButton
                  value="0"
                  v-model="corporateData.poNo"
                />
                <label>Yes</label>
              </div>
            </div>
            <div class="p-col-4">
              <div class="p-field-radiobutton">
                <RadioButton
                  value="1"
                  v-model="corporateData.poNo"
                />
                <label>No</label>
              </div>
            </div>
          </div>
        </div>
        <div class="p-field">
          <div class="p-grid">
            <div class="p-col-4">
              <p>Req From ?</p>
            </div>
            <div class="p-col-4">
              <div class="p-field-radiobutton">
                <RadioButton
                  value="0"
                  v-model="corporateData.reqForm"
                />
                <label>Yes</label>
              </div>
            </div>
            <div class="p-col-4">
              <div class="p-field-radiobutton">
                <RadioButton
                  value="1"
                  v-model="corporateData.reqForm"
                />
                <label>No</label>
              </div>
            </div>
          </div>
        </div>
        <div class="p-field">
          <h5>Add Extra Information</h5>
        </div>
        <div class="p-field">
          <div v-for="(input, k) in corporateData.extraInfo" :key="k">
            <div class="p-grid">
              <div class="p-col-9">
                <InputText
                  v-model.trim="input.fieldname"
                  placeholder="Field name"
                />
              </div>
              <div class="p-col-3">
                <Button
                  label=""
                  icon="pi pi-plus"
                  iconPos="right"
                  @click="addField"
                  v-show="k == corporateData.extraInfo.length - 1"
                />
              </div>
            </div>
          </div>
        </div>
        <template #footer>
          <Button
            type="submit"
            label="Save"
            icon="pi pi-check"
            class="p-button-primary"
            @click="saveItem"
          />
        </template>
      </Dialog>

      <Dialog
        v-model:visible="statusDialog"
        :style="{ width: '450px' }"
        header="Confirm"
      >
        <div class="confirmation-content">
          <i
            class="pi pi-exclamation-triangle p-mr-3"
            style="font-size: 2rem"
          />
          <span v-if="corporateData"
            >Change the status of <b>{{ corporateData.title }}</b> to
            {{ productStatus }}?</span
          >
        </div>
        <template #footer>
          <Button
            label="No"
            icon="pi pi-times"
            class="p-button-text"
            @click="statusDialog = false"
          />
          <Button
            label="Yes"
            icon="pi pi-check"
            class="p-button-text"
            @click="changeStatus"
          />
        </template>
      </Dialog>
    </div>
  </section>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import CorporateBranch from "../../service/CorporateBranch";
import Toaster from "../../helpers/Toaster";

@Options({
  components: {},
})
export default class CorporateBranches extends Vue {
  private lists = [];
  private CorporateBranch;
  private allSchedule;
  private allCorp;
  private productStatus = "";
  private keyword = "";
  private productDialog = false;
  private checkPagination = true;
  private statusDialog = false;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Corporate Branch" },
  ];
  private toast;

  private corporateData = {
    id: 0,
    corpHead: {},
    title: "",
    address: "",
    contact: "",
    email: "",
    description: "",
    fax: "",
    accounttype: "Charge",
    schedule: {},
    empNo: "0",
    poNo: "0",
    reqForm: "0",
    extraInfo: [{ fieldname: "" }],
    status: "",
    corporateLimit: 0
  };

  private accountTypes = [{ key: "Charge" }, { key: "COD" }];

  private dialogTitle;
  private dialogCallback;
  private submitted = false;

  // ADD ADDITIONAL INPUT FIELDS
  addField() {
    this.corporateData.extraInfo.push({
      fieldname: "",
    });
  }

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.CorporateBranch = new CorporateBranch();
    this.toast = new Toaster();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.clearItem();

    this.submitted = false;
    this.dialogTitle = "Create Corporate Profile";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();
    this.submitted = true;
    if (this.corporateData.title.trim()) {
      if (this.corporateData.id != 0) {
        this.CorporateBranch.updateItem(this.corporateData).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      } else {
        this.CorporateBranch.saveItem(this.corporateData).then((res) => {
          this.loadList(0);
          //SHOW NOTIFICATION
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.clearItem();
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Edit Corporate Profile";
    this.productDialog = true;

    this.CorporateBranch.getItem(data).then((res) => {
      if (res) {
        //FIND THE CATEGORY TYPE AND MAKE IT AS SELECTED IN EDIT DIALOG BOX.
       // const setAccountTypes = this.allAccountTypesFilter(res.account_type);
        const setAccountTypes = res.account_type;
        const setScheduleType = this.allScheduleFilter(res.price_list_id);
        const setCorpHeadType = this.allCorpHeadFilter(res.corporate_id);
        const setExtraInfo = this.extraInfoFilter(res.corporate_meta);
        this.corporateData.id = res.corporate_main_id;
        this.corporateData.corpHead = setCorpHeadType;
        this.corporateData.title = res.title;
        this.corporateData.address = res.address;
        this.corporateData.contact = res.contact;
        this.corporateData.email = res.email;
        this.corporateData.description = res.description;
        this.corporateData.fax = res.fax;
        this.corporateData.accounttype = setAccountTypes;
        this.corporateData.schedule = setScheduleType;
        this.corporateData.empNo = res.emp_no;
        this.corporateData.poNo = res.po_no;
        this.corporateData.reqForm = res.requisition_form;
        this.corporateData.extraInfo = setExtraInfo;
        this.corporateData.status = res.status;
        this.corporateData.corporateLimit = res.corporate_limit;

      }
    });
  }

  //USED TO FILTER TYPES
  // allAccountTypesFilter(res) {
  //   let accountTypeOpt = {};

  //   this.accountTypes.filter((elem) => {
  //     if (elem.key == res) {
  //       accountTypeOpt = elem;
  //     }
  //   });
  //   return accountTypeOpt;
  // }

  //USED TO FILTER TYPES
  allScheduleFilter(res) {
    let scheduleOpt = {};

    this.allSchedule.filter((elem) => {
      if (elem.id == res) {
        scheduleOpt = elem;
      }
    });
    return scheduleOpt;
  }

  //USED TO FILTER TYPES
  extraInfoFilter(res) {
    if (res.length <= 0) {
      const extraInfo = [{ fieldname: "" }];
      return extraInfo;
    } else {
      const extraInfo = [{ fieldname: "" }];
      extraInfo.splice(0, 1);
      res.map((item) => extraInfo.push({ fieldname: item.field_name }));
      return extraInfo;
    }
  }

  //USED TO FILTER TYPES
  allCorpHeadFilter(res) {
    let corpHeadOpt = {};

    this.allCorp.filter((elem) => {
      if (elem.corporate_id == res) {
        corpHeadOpt = elem;
      }
    });
    return corpHeadOpt;
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.corporateData.id = data.corporate_main_id;
    this.corporateData.title = data.title;
    this.corporateData.status = data.status;

    this.productStatus = status;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.corporateData.status = this.productStatus;
    this.CorporateBranch.changeStatus(this.corporateData).then((res) => {
      this.loadList(0);
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.CorporateBranch.getItems(page).then((data) => {
      this.lists = data.corporate_record;
      this.totalRecords = data.total_corporates_count;
      this.limit = data.limit_per_page;
      this.allCorp = data.corporate_parent;
      this.allSchedule = data.schedule;
    });
  }

  loadSearchData() {
    this.submitted = true;
    if (this.keyword) {
      this.CorporateBranch.getSearchedCorpBranch(this.keyword).then((data) => {
        this.lists = data.corporate_record;
        this.allCorp = data.corporate_parent;
        this.allSchedule = data.schedule;
        this.checkPagination = data.pagination;
      });
    }
  }
  clearItem() {
    this.corporateData.id = 0;
    this.corporateData.corpHead = this.allCorp[0];
    this.corporateData.title = "";
    this.corporateData.address = "";
    this.corporateData.contact = "";
    this.corporateData.email = "";
    this.corporateData.description = "";
    this.corporateData.fax = "";
    this.corporateData.schedule = this.allSchedule[0];
    this.corporateData.empNo = "0";
    this.corporateData.poNo = "0";
    this.corporateData.reqForm = "0";
    this.corporateData.extraInfo = [{ fieldname: "" }];
    this.corporateData.status = "";
    this.corporateData.corporateLimit = 0;
  }
}
</script>

<style scoped>
.product-image {
  width: 30px;
  height: auto;
}
</style>
