<template>
  <section>
    <div class="app-container">
      <Toolbar>
        <template v-slot:left>
           <h5> Generate Couchbase Indexes </h5>   
        </template>
      </Toolbar>
        <div class="text-center">
          <Button class="pi pi-primary btn-lg p-mt-4" label='Generete Indexes' @click="generateIndexes"/>
        </div>
    </div>
  </section>
</template>
<script lang="ts">
import { Options, Vue } from "vue-class-component";
import UserAuthentication from "../../service/UserAuthentication";
import Toaster from "../../helpers/Toaster";


@Options({
  components: {},
  title: 'Couchbase indexes'
})


export default class Services extends Vue {

   private toast;

   created() {
    this.toast = new Toaster();
  }

  generateIndexes()
  {
    const auth = new UserAuthentication();
   
    auth.generateIndexes().then((res) => {
        this.toast.handleResponse(res);
    });
  }
}
</script>
