
import { Options, Vue } from "vue-class-component";
import Customer from "../../service/Customer";
import Toaster from "../../helpers/Toaster";
import CustomerDialog from "../../components/CustomerDialog.vue";
import { camelCase } from "lodash";
import { useStore, ActionTypes } from "../../store";

@Options({
  components: {
    CustomerDialog,
  },
})
export default class Customers extends Vue {
  private lists = [];
  private keyword = "";
  private customerID = "";
  private customer;
  private toast;
  private submitted = false;
  private showPin = false;
  private productStatus = "";
  private customerStatus = "";
  private customerStoreID = "";
  private customerProfile = [];
   private dialogTitle = "";
  private permission = "yes";
  private title = "Add Customer";
  private statusDialog = false;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Customer" },
  ];

  private customers = {
    customerId: "",
    firstName: "",
    status: "Active",
  };

  private store = useStore();

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.customer = new Customer();
    this.toast = new Toaster();
  }

  mounted()
  {
    //MAKING CUSTOMER PHONE NO EMPTY
     this.store.dispatch(
      ActionTypes.GET_RECEIPT_ID,
      ''
    );
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.customers.customerId = data.customer_id;
    this.customers.firstName = data.first_name;

    this.productStatus = status;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.customers.status = this.productStatus;
    this.customer.changeStatus(this.customers).then((res) => {
      this.loadList();
      //SHOW NOTIFICATION
      this.toast.handleResponse(res);
    });
  }

  //FETCH THE DATA FROM SERVER
  loadList() {
    this.submitted = true;
    if (this.keyword) {
      this.customer.getItems(this.keyword.toLowerCase()).then((data) => {
        this.lists = data.search_result;
      });
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
   
    this.dialogTitle = "Update Customer";
      this.permission = "yes";
      this.customerStatus = "updateCustomer";
      this.customerID   = data.customer_id;

      this.customer.getItem(data.customer_id).then((res) => {
        this.customerProfile = this.camelizeKeys(res);
      });
  }

  //USED TO UPDATE THE PIN STATUS
  updateCustomerStatus() {
    this.customerStatus = "";
    this.loadList();
  }

  //USED TO OPEN THE ADD DIALOG MODEL
  openDialog() {
    this.customerStatus = "openCustomer";
    this.dialogTitle = "Customer Profile";
    this.permission = "yes";

      this.customer.openDialog().then((data) => {
        this.customerID =  data.customer_id;
        this.customerStoreID = data.branch_id;
      });
  }

  camelizeKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map((v) => this.camelizeKeys(v));
    } else if (obj !== null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [camelCase(key)]: this.camelizeKeys(obj[key]),
        }),
        {}
      );
    }
    return obj;
  };
}
