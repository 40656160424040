
import { Options, Vue } from "vue-class-component";
import UserAuthentication from "../../service/UserAuthentication";
import Toaster from "../../helpers/Toaster";


@Options({
  components: {},
  title: 'Couchbase indexes'
})


export default class Services extends Vue {

   private toast;

   created() {
    this.toast = new Toaster();
  }

  generateIndexes()
  {
    const auth = new UserAuthentication();
   
    auth.generateIndexes().then((res) => {
        this.toast.handleResponse(res);
    });
  }
}
