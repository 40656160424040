import router from "../../router";

export default class CheckinUtility {
  multiAxisData(onTheRackLabels, capacityLabels) {
    return {
      labels: [
        "09-10AM",
        "10-11AM",
        "11-12PM",
        "12-01PM",
        "01-02PM",
        "02-03PM",
        "03-04PM",
        "04-05PM",
        "05-06PM",
        "06-07PM",
        "07-08PM",
        "08-09PM",
        "09-10PM",
      ],
      datasets: [
        {
          label: "Rack $",
          backgroundColor: "#004c97",
          data: onTheRackLabels,
        },
        {
          label: "Capacity $",
          backgroundColor: "#FFA726",
          data: capacityLabels,
        },
      ],
    };
  }

  redirectHome() {
    router.push({ path: "/store/dashboard", params: {} });
  }

  redirectTransaction() {
    router.push({ path: "/process/transactions", params: {} });
  }

  setWeekDates(d) {
    const weekDatesList = [
      {
        dayName: "Sunday",
        dayDate: d.sunday,
      },
      {
        dayName: "Monday",
        dayDate: d.monday,
      },
      {
        dayName: "Tuesday",
        dayDate: d.tuesday,
      },
      {
        dayName: "Wednesday",
        dayDate: d.wednesday,
      },
      {
        dayName: "Thursday",
        dayDate: d.thursday,
      },
      {
        dayName: "Friday",
        dayDate: d.friday,
      },
      {
        dayName: "Saturday",
        dayDate: d.saturday,
      },
      {
        dayName: "Next Sunday",
        dayDate: d.nextSunday,
      },
      {
        dayName: "Next Monday",
        dayDate: d.nextMonday,
      },
      {
        dayName: "Next Tuesday",
        dayDate: d.nextTuesday,
      },
      {
        dayName: "Next Wednesday",
        dayDate: d.nextWednesday,
      },
      {
        dayName: "Next Thursday",
        dayDate: d.nextThursday,
      },
      {
        dayName: "Next Friday",
        dayDate: d.nextFriday,
      },
      {
        dayName: "Next Saturday",
        dayDate: d.nextSaturday,
      },
    ];

    return weekDatesList;
  }

totalExpressAmount(receiptServices) {
    let totalExpress = 0;
    receiptServices.forEach((e) => {
      if (e.express == "express") {
        totalExpress = totalExpress + Number(e.totalBill);
      }
    });

    return totalExpress;
  }

  getTotalPaid(paymentList) {
    let totalPaid = 0;
    let totalTendered = 0;
    let totalChange = 0;
    let totalRound = 0;

    paymentList.forEach((e) => {
      if (e.paymentType != "Tip") {
        totalPaid = totalPaid + Number(e.transTotalAmount);
        totalTendered = totalTendered + Number(e.tendered);
        totalChange = totalChange + Number(e.change);
        totalRound = totalRound + Number(e.roundOff);
      }
    });

    return [totalPaid, totalTendered, totalChange,totalRound];
  } 
  
  getCurrentNeedles(paymentList) {
    let totalPoints = 0;
    paymentList.forEach((e) => {
      if (e.paymentType != "Tip" && e.paymentType != "Needle") {
        totalPoints = totalPoints + Number(e.transTotalAmount);
      }
    });
    totalPoints = Number((totalPoints/20).toFixed(2));
    return totalPoints;
  }



  totalDiscountAmount(receiptServices,customerReceipt) {

    let totalDiscount = 0;
    let subtotal = 0;

    receiptServices.forEach((e) => {
      totalDiscount = totalDiscount + Number(e.totalDiscountAmt);
      subtotal = subtotal + Number(e.totalBill);
    });

    if (customerReceipt.discount != 0) {
      if (customerReceipt.discountMethod == "%") {
        totalDiscount =
          totalDiscount + (subtotal / 100) * customerReceipt.discount;
      } else {
        totalDiscount = totalDiscount + customerReceipt.discount;
      }
    }

    customerReceipt.totalDiscount = Number(totalDiscount);

    return Number(totalDiscount);
  }

  getServiceQty(qty,serviceId)
  {
    let q = 0;

    //IF DRY CLEAN SET QTY TO 1
    if(serviceId == '3221190018')
    {
      qty = 1;
    }
    
    return Number(qty);
  }
}
