
import { Options, Vue } from "vue-class-component";
import Coupon from "../../service/Coupon";
import Toaster from "../../helpers/Toaster";
import VueBarcode from '@chenfengyuan/vue-barcode';

@Options({
  components: {
    VueBarcode
  },
})
export default class Coupons extends Vue {
  private imagePath = "";
  private couponNo = "";
  private lists = [];
  private allServices;
  private couponMethods = [
    {
      name: 'Percentage',
      value: '%',
    },
    {
      name: 'Amount',
      value: '$',
    }
  ]
  private coupon;
  private keyword = "";
  private productStatus = "";
  private couponBarcodeDialog = false;
  private loading = false;
  private checkPagination = true;
  private productDialog = false;
  private statusDialog = false;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Coupon" },
  ];


  private barcodeOptions = { 
    displayValue: false,
    lineColor: '#000',
   // format: 'CODE39',
    width: 2,
    height: 50,
    font: 'fantasy',
    textAlign: 'left',
  }


  private itemTypes = [{ key: "Product" }, { key: "Service" }];

  private popupConditions = [{ key: "No" }, { key: "Yes" }];

  private product = {
    id: 0,
    name: "",
    barcode: "",
    copounfor: "",
    percentage: 0,
    description: "",
    discountMethod: "",
    services: {},
    startdate: "",
    enddate: "",
    status: "",
  };

  private dialogTitle;
  private dialogCallback;
  private submitted = false;

  private serviceId = "";
  private serviceObj;

  private departmentId = "";
  private departmentObj;

  private selectedPopConditionKey = "";
  private selectedPopConditionObj;

  private selectedItemTypeKey = "";
  private selectedItemTypeObj;
  private toast;

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }

  // CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  created() {
    this.coupon = new Coupon();
    this.imagePath = this.coupon.getBaseURL() + "uploads/services/";
    this.toast = new Toaster();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.product = {
      id: 0,
      name: "",
      barcode: "",
      copounfor: "",
      services: {},
      percentage: 0,
      discountMethod: "",
      description: "",
      startdate: "",
      enddate: "",
      status: "",
    };

    this.submitted = false;
    this.dialogTitle = "Add Coupon";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();
    this.submitted = true;
    if (this.product.name.trim()) {
      if (this.product.id != 0) {
        this.coupon.updateItem(this.product).then((res) => {
          this.loadList(0);
          this.toast.handleResponse(res);
        });
      } else {
        this.coupon.saveItem(this.product).then((res) => {
          this.loadList(0);
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.product = {
        id: 0,
        name: "",
        barcode: "",
        copounfor: "",
        services: {},
        percentage: 0,
        discountMethod: '',
        description: "",
        startdate: "",
        enddate: "",
        status: "",
      };
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Edit Coupon";
    this.productDialog = true;

    this.coupon.getItem(data).then((res) => {
      if (res.length > 0) {
        let selectedService;

        this.allServices.filter((elem) => {
          if (elem.id == res[0].service_id) {
            selectedService = elem;
          }
        });

        let selectedMethod;

        if(res[0].discount_method == '%')
        {
          selectedMethod =  {
            name: 'Percentage',
            value: '%'
          }
        }
        else
        {
          selectedMethod =  {
            name: 'Amount',
            value: '$',
          }
        }

        this.product = {
          id: res[0].id,
          name: res[0].discount_name,
          barcode: res[0].discount_barcode,
          copounfor: res[0].discount_type,
          services: selectedService,
          percentage: res[0].discount_percentage,
          discountMethod: selectedMethod,
          description: res[0].description,
          startdate: res[0].starts_date,
          enddate: res[0].end_date,
          status: res[0].status,
        };
      }
    });
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.product = {
      id: data.id,
      name: data.discount_name,
      barcode: "",
      copounfor: "",
      services: {},
      percentage: 0,
      discountMethod: '',
      description: "",
      startdate: "",
      enddate: "",
      status: data.status,
    };
    this.productStatus = status;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.product.status = this.productStatus;
    this.coupon.changeStatus(this.product).then((res) => {
      this.loadList(0);
      this.toast.handleResponse(res);
    });
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.loading = true;
    this.coupon.getItems(page).then((data) => {
      this.lists = data.discount_list;
      this.totalRecords = data.total_coupons_count;
      this.limit = data.limit_per_page;
      this.allServices = data.services;
      this.loading = false;
    });
  }

  loadSearchData() {
    this.submitted = true;
    if (this.keyword) {
      this.coupon.getSearchedCoupons(this.keyword).then((data) => {
        this.lists = data.discount_list;
        this.allServices = data.services;
        this.checkPagination = data.pagination;
      });
    }
  }

  clearFields() {
    this.product.percentage = 0;
    this.product.description = "";
    this.product.services = {};
    this.product.startdate = "";
    this.product.enddate = "";
  }

  previewBarcode(barcode)
  {
    if(barcode != '')
    {
      this.couponBarcodeDialog = true;
      this.couponNo = barcode;
    }
    else
    {
      this.toast.showWarning('Sorry barcode is not avaibale to preview');
    }
  }

  closeDialogBox()
  {
    this.couponBarcodeDialog = false;
  }

  printDoc()
  {
    window.print();
  }
}
