
import { Options, mixins } from "vue-class-component";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import CheckinService from "../../service/CheckinService";
import ItemConditions from "../../components/ItemConditions.vue";
import ServiceQty from "../../components/ServiceQty.vue";
import ItemQty from "../../components/ItemQty.vue";
import Coupons from "../../components/Coupons.vue";
import DueDate from "../../components/DueDate.vue";
import PauseReceipt from "../../components/PauseReceipt.vue";
import ReferralorCorp from "../../components/ReferralorCorp.vue";
import PaymentScreen from "../../components/PaymentScreen.vue";
import AssociatePinVerification from "../../components/AssociatePinVerification.vue";
import Toaster from "../../helpers/Toaster";
import moment from "moment";
import { useStore, ActionTypes } from "../../store";
import router from "../../router";
import AutoComplete from "primevue/autocomplete";
import Customer from "../../service/Customer";
import CustomerDialog from "../../components/CustomerDialog.vue";
import CheckinUtility from "../checkIn/CheckinUtility";
import UtilityOptions from "../../mixins/UtilityOptions";
import {
  ServiceList,
  DepartList,
  ItemList,
  ColorList,
  TypeList,
  UpchargeList,
  ConditionList,
  ConditionType,
  ModifiedConditionType,
  ReceiptServices,
  ServiceItem,
  ReceiptType,
  CustomerSearchFields,
  TaxedItem,
  PriceList,
  WeekDates,
} from "../checkIn/ICheckin";

@Options({
  props: {
    receiptID: String,
  },
  components: {
    TabView,
    TabPanel,
    ItemConditions,
    ServiceQty,
    ItemQty,
    DueDate,
    Coupons,
    PauseReceipt,
    AutoComplete,
    ReferralorCorp,
    AssociatePinVerification,
    PaymentScreen,
    CustomerDialog,
  },
  title: "Check In",
  // emits: ["emitItemDetailEvent"],
})
export default class CheckIn extends mixins(UtilityOptions) {
  private dialogTitle = "Customer Payment Method";
  private verificationType = "";
  private actionType = "";
  private receiptID = null;
  private checkUtil = new CheckinUtility();
  private toast;
  private checkTxn;
  private customerService;
  private dryCleanConfirmDialog = false;
  private paymentDialog = false;
  private corporateConfirmDialog = false;
  private statusDialog = false;
  private selectedPriceList: PriceList = {
    id: "",
    scheduleName: "",
  };
  private referralorCorpDialog = false;
  private fixedExpressToday = 0;
  private corporateLimit = 0;
  private pauseReceiptDialog = false;
  private imagePathService = "";
  private imagePathDepartment = "";
  private conditionsList: ConditionType[] = [];
  private couponList = [];
  private weekDatesList: WeekDates[] = [];
  private customerHistory = null;
  private modifiedConditionList: ModifiedConditionType[] = [];
  private receiptServices: ReceiptServices[] = [];
  private checkedTags: ColorList[] = [];
  private checkedCrease = "";
  private checkedFolding = "";
  private checkedUpcharges: UpchargeList[] = [];
  private serviceItemList: TaxedItem[] = [];
  private customerReceipt: ReceiptType = {
    id: "",
    date: this.getReceiptDate,
    timeEntrance: "",
    dueDate: "",
    dueTime: "",
    canadaExempt: "",
    provinceExempt: "",
    exempt1: "",
    exempt2: "",
    discount: 0,
    discountMethod: "",
    discountName: "",
    discountId: "",
    totalDiscount: 0,
    description: "",
    cusId: "",
    customer: "",
    customerTel: "",
    customerEmail: "",
    taxCode: "",
    employeeName: "",
    branchCode: "",
    telephone: "",
    branchName: "",
    taxNo: "",
    address: "",
    customerNeedles: 0,
    currentNeedles: 0,
    totalBill: 0,
    subTotal: 0,
    totalAfterDisc: 0,
    totalPaid: 0,
    totalExpress: 0,
    totalQty: 0,
    storeId: "",
    employeeId: "",
    priceListId: "",
    accountType: "",
    referalOrCorp: "",
    referralOrCorpName: "",
    referalOrCorpId: "",
    corpEmp: "",
    corpPo: "",
    corpReq: "",
    corporateMeta: [],
    status: "",
    taxName: "",
    taxAmountPrice: 0,
    paymentMethod: "",
    totalTendered: 0,
    totalChange: 0,
    totalRound: 0,
    invoiceServices: this.computedServices,
    invoicePayment: [],
    invoiceWavier: [],
    invoiceTaxes: [],
  };

  private capacityLabels = [];
  private onTheRackLabels = [];
  private scheduleDefault = "";
  private customerList: CustomerSearchFields[] = [];
  private capacityDate = "";
  private totalRack = 0;
  private componentName = "";
  private selectedCustomer = "";
  private itemQty = 0;
  private expressAmount = 0;
  private price = 0;
  private itemIndex = 0;
  private serviceIndex = 0;
  private serviceDesc = "";
  private discountId = "";
  private discountMethod = "";
  private discountName = "";
  private discount = 0;
  private express = "";
  private serviceExpressDate = "";
  private serviceExpressTime = "";
  private serviceCouponID = "";
  private description = "";
  private dueDateDialog = false;
  private conditionDialog = false;
  private serviceDialog = false;
  private itemQtyDialog = false;
  private couponDialog = false;
  private store = useStore();
  private activeServiceObj = {
    id: "",
    name: "",
  };

  private activeDepartmentObj = {
    id: "",
    sector: "",
  };

  private activeItemObj = {
    id: "",
    itemId: "",
    itemName: "",
    price: 0,
    hasCondition: "",
  };

  private serviceList: ServiceList[] = [];

  private departList: DepartList[] = [];

  private itemList: ItemList[] = [];

  private colorList: ColorList[] = [];

  private typeList: TypeList[] = [];

  private invoiceWavier: ColorList[] = [];

  private upchargeList: UpchargeList[] = [];

  private conditionList: ConditionList[] = [];

  private creaseValue = ["None", "Yes", "No"];

  private foldingValue = ["None", "Hanger", "Folded"];

  private priceList: PriceList[] = [];

  private newCustomerID = "";
  private customerStoreID = "";
  private customerProfile = [];
  private permission = "";
  private customerStatus = "";

  private multiAxisOptions = {
    indexAxis: "y",
    responsive: true,
    responsiveAnimationDuration: 500,
    maintainAspectRatio: false,
  };

  //DEFAULT METHOD OF TYPE SCRIPT
  //CALLING WHENEVER COMPONENT LOADS
  created() {
    this.checkTxn = new CheckinService();
    this.customerService = new Customer();
    this.toast = new Toaster();
    this.imagePathService = this.checkTxn.getBaseURL() + "uploads/services/";
    this.imagePathDepartment =
      this.checkTxn.getBaseURL() + "uploads/departments/";
  }

  mounted() {
    this.verificationType = "associate";
    this.getCapacityChart();
  }

  openCustomerDialog() {
    this.dialogTitle = "Add Customer";
    this.permission = "yes";
    this.customerStatus = "openCustomer";
    this.store.dispatch(
      ActionTypes.GET_RECEIPT_ID,
      this.selectedCustomer
    );

    this.customerService.openDialog().then((data) => {
      this.newCustomerID = data.customer_id;
      this.customerStoreID = data.branch_id;
    });
  }

  //USED TO UPDATE THE PIN STATUS
  updateCustomerStatus(param) {
    this.customerStatus = "";
    this.customerReceipt.cusId = param[0];
    this.customerReceipt.customer = param[1];
    this.selectedCustomer = param[1];
    this.customerReceipt.customerEmail = param[2];
    this.customerReceipt.customerTel = param[3];

    if (param[4] == "save") {
      this.openReferalOrCorp();
    }
    if (param[4] == "update") {
      //COMMING WHEN UPDATING CUSTOMER PROFILE
      this.clearReceipt();
    }
  }

  searchCustomer(event) {
    this.checkTxn.searchCustomer(event.query.trim()).then((data) => {
      const d = this.camelizeKeys(data);
      const searchedResult: CustomerSearchFields[] = [];
      this.customerList = [];

      d.forEach((e) => {
        const cus: CustomerSearchFields = {
          contact: e.contact,
          firstName: e.firstName,
          lastName: e.lastName,
          email: e.email,
          customerMood: e.customerMood,
          customerId: e.customerId,
        };

        searchedResult.push(cus);
      });

      this.customerList = searchedResult;
    });
  }

  get progressBar() {
    return this.store.getters.getProgressBar;
  }

  getSetUp() {
    this.checkTxn.getSetUp().then((data) => {
      if (data.alert == "danger") {
        this.toast.handleResponse(data);
        this.checkUtil.redirectHome();
      } else {
        this.capacityDate = data.todayDate;
        this.priceList = this.camelizeKeys(data.schedule_list);
        this.colorList = this.camelizeKeys(data.colorlist);
        this.conditionList = this.camelizeKeys(data.condlist);
        this.upchargeList = this.camelizeKeys(data.upchargelist);
        this.typeList = this.camelizeKeys(data.typelist);
        this.scheduleDefault = this.camelizeKeys(data.schedule_default);
        this.totalRack = this.camelizeKeys(data.total_rack);
        const taxes = this.camelizeKeys(data.tax_array);
        this.customerReceipt.invoiceTaxes = taxes;
        this.setWeekDates(data);

        //IF NOT UPDATING THE RECEIPT
        if (this.receiptID == null) {
          this.serviceList = this.camelizeKeys(data.servicelist);
          this.customerReceipt.priceListId = this.camelizeKeys(
            data.schedule_default
          );
          this.setPriceList(data.schedule_default);
        } else {
          //CALLS WHEN EDIT
          this.fetchReceipt();
        }
      }
    });
  }

  getServices() {
    this.checkTxn
      .getScheduleService(this.customerReceipt.priceListId)
      .then((data) => {
        if (data.alert == "danger") {
          this.toast.handleResponse(data);
          this.checkUtil.redirectHome();
        } else {
          this.serviceList = this.camelizeKeys(data.servicelist);
        }
      });
  }

  getCapacityChart() {
    this.checkTxn.getCapacityChart(this.capacityDate).then((data) => {
      this.capacityLabels = data.capacity;
      this.onTheRackLabels = data.rackAmount;
    });
  }

  getDepartment(service) {
    if (this.customerReceipt.cusId != "") {
      this.activeServiceObj.id = service.id;
      this.activeServiceObj.name = service.name;
      this.resetTags();

      //CHECKING IF FOR SAME DRY CLEAN
      if(service.id == '3221190018')
      {
        this.dryCleanConfirmDialog = true;
      }
      
      const receiptService: ReceiptServices = {
        id: moment().format("x"),
        invId: "",
        date: "",
        serviceId: service.id,
        serviceName: service.name,
        serviceType: service.serviceType,
        discountId: "",
        discount: 0,
        discountMethod: "",
        discountName: "",
        totalDiscountAmt: 0,
        qty: 1,
        tax: 0,
        description: "",
        tag1: "",
        tag2: "",
        dueDate: "",
        dueTime: "",
        upchargeCharges: 0,
        upchargeChargesPer: 0,
        express: "no",
        expressAmt: 0,
        expressNotification: "",
        totalBill: 0,
        checkStatus: "",
        workingStatus: "",
        checkRedo: "",
        createdEmpId: "",
        storeId: "",
        completedTime: "",
        completedDate: "",
        startTime: "",
        startDate: "",
        itemList: [],
        workingEmployee: "",
        internalStatus: 'Parent',
        invoiceWavier: [],
      };

      this.receiptServices.push(receiptService);

      this.activeDepartmentObj.id = "";
      this.activeDepartmentObj.sector = "";

      this.itemList = [];

      this.checkTxn
        .getDepartments(
          this.activeServiceObj.id,
          this.customerReceipt.priceListId
        )
        .then((data) => {
          this.departList = this.camelizeKeys(data);
        });
    } else {
      this.toast.showWarning("Please select the customer first");
    }
  }


  dryCleanForSame(status)
  {
    this.dryCleanConfirmDialog = false;
   
    if(status == 'Yes')
    {
      if(this.receiptServices.length > 1)
      {
        const  serviceLen = this.receiptServices.length - 2;  
        this.receiptServices[serviceLen].internalStatus = 'Child';
         
        //SET THE QTY OF THE SERVICE FOR DC 0
        const  l = this.receiptServices.length - 1;  
        this.receiptServices[l].qty = 0;
      }
      else
      {
        this.toast.showError('No service is available to apply Dry Clean');
      }
    }
  }

  get computedServices() {
    return this.receiptServices;
  }

  setUpCharges(upcharge) {
    let checkExists = false;

    this.checkedUpcharges.filter((e, i) => {
      if (e.id == upcharge.id) {
        this.checkedUpcharges.splice(i, 1);
        checkExists = true;
      }
    });

    if (checkExists == false) {
      this.checkedUpcharges.push(upcharge);
    }

    this.prepareTag2();
  }

  prepareTag2() {
    let tag2 = "";
    let symbol = "";

    if (this.checkedCrease != "None") {
      tag2 = " Crease : " + this.checkedCrease + " / ";
    }

    if (this.checkedFolding != "None") {
      tag2 = tag2 + " Folding : " + this.checkedFolding + " / ";
    }

    this.checkedUpcharges.forEach((e) => {
      if (e.optChargeValue == "percentage") {
        symbol = "%";
      } else {
        symbol = "$";
      }

      tag2 =
        tag2 + " " + e.priceName + " " + e.chargeValue + " " + symbol + " / ";
    });

    if (this.receiptServices.length > 0) {
      this.receiptServices[0].tag2 = tag2;
    }
  }

  resetTags() {
    this.checkedCrease = "None";
    this.checkedFolding = "None";
    this.checkedTags = [];
    this.checkedUpcharges = [];
  }

  getItems(depart) {
    this.activeDepartmentObj.id = depart.id;
    this.activeDepartmentObj.sector = depart.sector;
    this.checkTxn
      .getItems(
        this.activeServiceObj.id,
        depart.id,
        this.customerReceipt.priceListId
      )
      .then((data) => {
        this.itemList = this.camelizeKeys(data);
      });
  }

  getItemConditions(item) {
    if (
      this.corporateLimit > 0 &&
      this.subtotals + item.price >= this.corporateLimit
    ) {
      this.toast.showInfo(
        "You cannot extend from the corporate limit of $" + this.corporateLimit
      );
    } else {
      this.activeItemObj = item;
      this.modifiedConditionList = [];

      let serviceLen = 0;

      if (this.receiptServices.length > 0) {
        serviceLen = this.receiptServices.length - 1;
      }

      //LAST SERVICE ITEMS LENGTH
      const itemListLength = this.receiptServices[serviceLen].itemList.length;

      const serviceItem: ServiceItem = {
        salesItemId: itemListLength,
        departmentId: this.activeDepartmentObj.id,
        departmentName: this.activeDepartmentObj.sector,
        itemId: item.itemId,
        itemName: item.itemName,
        itemQty: 1,
        price: Number(item.price),
        tag3: "",
        itemDetailCharges: 0,
        tax: item.taxes,
      };

      this.receiptServices[serviceLen].itemList.push(serviceItem);
      this.receiptServices[serviceLen].itemList;

      if (this.activeItemObj.hasCondition == "Yes") {
        this.conditionDialog = true;
        this.checkTxn
          .getItemConditions(
            this.activeItemObj.itemId,
            this.customerReceipt.priceListId
          )
          .then((data) => {
            this.conditionsList = this.camelizeKeys(data);
            this.conditionsList.forEach((e) => {
              this.modifiedConditionList.push({
                conditionStatus: e.conditionStatus,
                condittionPrice: e.condittionPrice,
                itemId: e.itemId,
                itemSpId: e.itemSpId,
                itemSpName: e.itemSpName,
                parentItemId: e.parentItemId,
                sectionName: e.sectionName,
                quantity: 1,
                totalAmount: e.condittionPrice,
                checkedItem: false,
              });
            });
          });
      } else {
        this.conditionDialog = false;
      }

      this.calculateTotals();
    }
  }

  adjustServiceQty(service, serviceIndex) {
    this.checkTxn.getServicesCoupon(service.serviceId).then((data) => {
      const d = this.camelizeKeys(data);
      this.couponList = d.serviceCoupons;
      this.fixedExpressToday = Number(d.expressAmount);
    });

    this.serviceDialog = true;
    this.serviceIndex = serviceIndex;
    this.itemQty = service.qty;
    this.serviceDesc = service.description;
    this.serviceExpressDate = service.dueDate;
    this.serviceExpressTime = service.dueTime;
    this.discountId = service.discountId;
    this.express = service.express;
  }

  adjustItemQty(itemDetail, itemIndex, serviceIndex) {
    this.itemIndex = itemIndex;
    this.serviceIndex = serviceIndex;
    this.itemQty = itemDetail.itemQty;
    this.price = itemDetail.price;
    this.itemQtyDialog = true;
  }

  openDueDate() {
    if (this.customerReceipt.cusId != "") {
      this.dueDateDialog = true;
    } else {
      this.toast.showWarning("Please select the customer first");
    }
  }

  closeItemPopup(params) {
    let tag3 = "";
    let charges = 0;

    let serviceIndex = 0;
    let itemIndex = 0;

    if (this.receiptServices.length > 0) {
      serviceIndex = this.receiptServices.length - 1;
    }

    if (this.receiptServices[serviceIndex].itemList.length > 0) {
      itemIndex = this.receiptServices[serviceIndex].itemList.length - 1;
    }

    params.forEach((e) => {
      if (e.checkedItem == true) {
        const amt = e.totalAmount;
        charges = charges + Number(amt);
        tag3 =
          tag3 +
          "(" +
          e.quantity +
          "x) " +
          e.itemSpName +
          " $" +
          this.formatAmount(amt) +
          " | ";
      }
    });

    if (charges > 0) {
      this.receiptServices[serviceIndex].itemList[
        itemIndex
      ].itemDetailCharges = charges;
    }

    if (tag3 != "") {
      this.receiptServices[serviceIndex].itemList[itemIndex].tag3 = tag3;
    }

    this.calculateTotals();

    this.conditionDialog = false;
  }

  closeServicePopup(params) {
    const reversedIdx = this.receiptServices.length - 1 - this.serviceIndex;

    if (params[0] == "Done") {
      this.receiptServices[reversedIdx].express = params[5];

      if (params[5] == "express") {
        this.toast.showSuccess("Service marked as EXPRESS");

        this.receiptServices[reversedIdx].dueDate = params[4];
        this.receiptServices[reversedIdx].dueTime = params[3];
      } else {
        this.receiptServices[reversedIdx].dueDate = "";
        this.receiptServices[reversedIdx].dueTime = "";
      }
      this.receiptServices[reversedIdx].description = params[2];
      this.receiptServices[reversedIdx].expressAmt = 15;
      this.receiptServices[reversedIdx].discountId = params[6];
      this.receiptServices[reversedIdx].discountMethod = params[8];
      this.receiptServices[reversedIdx].discountName = params[7];
      this.receiptServices[reversedIdx].discount = Number(params[9]);
      this.receiptServices[reversedIdx].qty = Number(params[1]);

      this.toast.showSuccess("Service adjustment done successfully");

      this.calculateTotals();
    } else if (params[0] == "Cancel") {
      this.toast.showSuccess("Service adjustment cancelled successfully");
    }

    this.serviceDialog = false;
  }

  closeItemQtyDialog(params) {
    const reversedIdx = this.receiptServices.length - 1 - this.serviceIndex;

    if (params[0] == "Done") {
      this.receiptServices[reversedIdx].itemList[this.itemIndex].itemQty =
        params[1];
      this.receiptServices[reversedIdx].itemList[this.itemIndex].price =
        params[2];

      this.toast.showSuccess("Item adjustment done successfully");

      this.calculateTotals();
    } else if (params[0] == "Cancel") {
      this.toast.showSuccess("Item adjustment cancelled successfully");
    } else if (params[0] == "Delete") {
      this.receiptServices[reversedIdx].itemList.splice(this.itemIndex, 1);
      this.toast.showSuccess("Item deleted successfully");
      this.calculateTotals();
    }

    this.itemQtyDialog = false;
  }

  closeCouponDialog(params) {
    if (params.length <= 0) {
      this.toast.showInfo("No coupon is applied");
    } else {
      if (
        (params[0].discountMethod == "%" &&
          Number(params[0].discount) < 100 &&
          Number(params[0].discount) > 0) ||
        (params[0].discountMethod == "$" &&
          Number(this.subtotals) >= Number(params[0].discount))
      ) {
        this.customerReceipt.discountMethod = params[0].discountMethod;
        this.customerReceipt.discountId = params[0].discountId;
        this.customerReceipt.discount = Number(params[0].discount);
        this.customerReceipt.discountName = params[0].discountName;

        this.toast.showSuccess(
          params[0].discountName + " coupon is applied successfully"
        );

        this.calculateTotals();
      } else {
        this.toast.showError(
          params[0].discountName +
            " value is invalid seems like amount is greater then subtotal or invalid percentage"
        );
      }
    }

    this.couponDialog = false;
  }

  closePauseDialog(params) {
    if (params[0] == "paused") {
      this.clearReceipt();
      this.checkUtil.redirectHome();
    } else if (params[0] == "resume") {
      const data = params[1];
      this.receiptServices = data.invoiceServices;
      this.customerReceipt = data;
      this.selectedCustomer = data.customer;
      this.calculateTotals();
    }

    this.pauseReceiptDialog = false;
  }

  closeDueDateDialog(params) {
    if (params[2] == "closeDialog") {
      this.toast.showInfo("Due Date & Time is not saved");
    } else {
      this.customerReceipt.dueDate = params[0];
      this.customerReceipt.dueTime = params[1];
      //SETTING SERVICES DUE DATE AND TIME
      this.receiptServices.forEach(e => {
         if(e.express != 'express')
         {
            e.dueDate = params[0];
            e.dueTime = params[1];
         }
      });

      this.toast.showSuccess("Due Date & Time saved Successfully");
    }

    this.dueDateDialog = false;
  }

  deleteService(index) {
    const reversedIdx = this.receiptServices.length - 1 - index;

    this.receiptServices.splice(reversedIdx, 1);

    this.activeDepartmentObj.id = "";
    this.activeDepartmentObj.sector = "";

    this.activeServiceObj.id = "";
    this.activeServiceObj.name = "";

    this.toast.showSuccess("Service deleted successfully");

    this.calculateTotals();
  }

  get totalServices() {

    let t = 0;

    this.receiptServices.forEach(e => {
      t = t + e.qty;
    });

    return t;
  }

  openCouponDailog() {
    this.couponDialog = true;
    this.couponList = [];
    this.checkTxn.getCoupons().then((data) => {
      this.couponList = this.camelizeKeys(data);
    });
  }

  clearReceipt() {
    this.receiptServices = [];

    this.customerReceipt.id = "";
    this.customerReceipt.timeEntrance = "";
    this.customerReceipt.dueDate = "";
    this.customerReceipt.dueTime = "";
    this.customerReceipt.canadaExempt = "";
    this.customerReceipt.provinceExempt = "";
    this.customerReceipt.exempt1 = "";
    this.customerReceipt.exempt2 = "";
    this.customerReceipt.discount = 0;
    this.customerReceipt.discountMethod = "";
    this.customerReceipt.discountName = "";
    this.customerReceipt.discountId = "";
    this.customerReceipt.description = "";
    this.customerReceipt.cusId = "";
    this.customerReceipt.customer = "";
    this.customerReceipt.customerTel = "";
    this.customerReceipt.customerEmail = "";
    this.customerReceipt.taxCode = "";
    this.customerReceipt.branchCode = "";
    this.customerReceipt.telephone = "";
    this.customerReceipt.branchName = "";
    this.customerReceipt.taxNo = "";
    this.customerReceipt.address = "";
    this.customerReceipt.subTotal = 0;
    this.customerReceipt.totalAfterDisc = 0;
    this.customerReceipt.customerNeedles = 0;
    this.customerReceipt.currentNeedles = 0;
    this.customerReceipt.totalBill = 0;
    this.customerReceipt.totalPaid = 0;
    this.customerReceipt.totalExpress = 0;
    this.customerReceipt.totalQty = 0;
    this.customerReceipt.storeId = "";
    this.customerReceipt.priceListId = this.scheduleDefault;
    this.customerReceipt.accountType = "";
    this.customerReceipt.referalOrCorp = "";
    this.customerReceipt.referalOrCorpId = "";
    this.customerReceipt.referralOrCorpName = "";
    this.customerReceipt.corpEmp = "";
    this.customerReceipt.corpPo = "";
    this.customerReceipt.corpReq = "";
    this.customerReceipt.corporateMeta = [];
    this.customerReceipt.status = "";
    this.customerReceipt.paymentMethod = "";
    this.customerReceipt.totalTendered = 0;
    this.customerReceipt.totalChange = 0;
    this.customerReceipt.totalRound = 0;
    this.customerReceipt.totalDiscount = 0;
    this.customerReceipt.invoiceServices = this.computedServices;
    this.customerReceipt.invoicePayment = [];
    this.customerReceipt.invoiceWavier = [];
    this.customerReceipt.invoiceTaxes[0].taxAmt = 0;
    this.customerReceipt.invoiceTaxes[1].taxAmt = 0;

    this.activeDepartmentObj.id = "";
    this.activeDepartmentObj.sector = "";

    this.activeItemObj.id = "";
    this.activeItemObj.itemId = "";
    this.activeItemObj.itemName = "";
    this.activeItemObj.price = 0;
    this.activeItemObj.hasCondition = "";

    this.selectedCustomer = "";

    this.statusDialog = false;

    this.departList = [];

    this.itemList = [];

    this.getCapacityChart();

    this.toast.showSuccess("Receipt cleared successfully");
  }

  openPauseResumeReceipt()
  {
    this.pauseReceiptDialog = true;
    this.setAllDetails();
  }

  get getReceiptDate() {
    return moment().format("YYYY-MM-DD");
  }

  saveCustomer(event) {
    const custInfo = event.value;
    this.selectedCustomer = custInfo.firstName + " " + custInfo.lastName;
    this.customerReceipt.customer =
       custInfo.lastName+ ", " + custInfo.firstName;
    this.customerReceipt.cusId = custInfo.customerId;
    this.customerReceipt.customerEmail = custInfo.email;
    this.customerReceipt.customerTel = custInfo.contact;

    this.openReferalOrCorp();
  }

  openReferalOrCorp() {
    //referralOrCorp
    this.checkTxn.referralOrCorp(this.customerReceipt.cusId).then((data) => {
      this.customerHistory = this.camelizeKeys(data);
    });

    this.referralorCorpDialog = true;
  }

  closeRefOrCorpDialog(params) {
    this.referralorCorpDialog = false;

    if (params[1] == "closeDialog") {
      this.selectedCustomer = "";
      this.customerReceipt.cusId = "";
      this.customerReceipt.customerEmail = "";
      this.customerReceipt.customerTel = "";
      this.customerReceipt.customerNeedles = 0;
      this.customerReceipt.currentNeedles = 0;
      this.customerReceipt.accountType = "";
      this.customerReceipt.referalOrCorp = "";
      this.customerReceipt.referalOrCorpId = "";
      this.customerReceipt.corpEmp = "";
      this.customerReceipt.corpPo = "";
      this.customerReceipt.corpReq = "";
      this.customerReceipt.corporateMeta = [];
      this.customerReceipt.exempt1 = "";
      this.customerReceipt.exempt2 = "";
      this.customerReceipt.canadaExempt = "";
      this.customerReceipt.provinceExempt = "";

      this.toast.showInfo("Please select any customer to continue process");
    } else if (params[1] == "next") {
      const res = params[0];

      const customerTaxDetail = params[2];

      this.customerReceipt.customerNeedles = res.Neddle;
      this.customerReceipt.referalOrCorp = res.selectedAction;
      this.customerReceipt.referalOrCorpId = res.referralorCorpID;
      this.customerReceipt.accountType = res.accountType;
      this.customerReceipt.corpEmp = res.empData;
      this.customerReceipt.corpPo = res.poData;
      this.customerReceipt.corpReq = res.reqData;
      this.customerReceipt.corporateMeta = res.corporateMeta;
      this.customerReceipt.exempt1 = customerTaxDetail.exempt1;
      this.customerReceipt.exempt2 = customerTaxDetail.exempt2;
      this.customerReceipt.canadaExempt = customerTaxDetail.hstExempt;
      this.customerReceipt.provinceExempt = customerTaxDetail.pstExempt;
      this.customerReceipt.referralOrCorpName = res.referralOrCorpName;

      if (
        res.selectedAction == "Referral" ||
        res.selectedAction == "corporate"
      ) {
        this.toast.showInfo("Receipt Tag is for " + res.selectedAction);
        this.selectedCustomer =
          this.selectedCustomer + " | " + res.referralOrCorpName;
      }

      if (res.priceListId != "" && res.selectedAction == "corporate") {
        this.customerReceipt.priceListId = res.priceListId;
        this.corporateLimit = res.corporateLimit;
        this.setPriceList(res.priceListId);
        this.getServices();
        this.toast.showInfo(
          "Price List is changed and locked for corporate. Price List ID assigned for " +
            res.referralOrCorpName +
            " is " +
            res.priceListId
        );

        this.toast.showWarning(
          "The limit for the corporate is $" + this.corporateLimit
        );
      } else {
        //this.customerReceipt.priceListId = this.scheduleDefault;
        this.corporateLimit = 0;
        // this.getServices();
      }

      this.departList = [];
      this.itemList = [];

      this.toast.showSuccess(
        "Step completed successfully. Now you can add  services"
      );
    }
  }

  processPriceList(event) {
    if (this.customerReceipt.priceListId != event.value.id) {
      this.customerReceipt.priceListId = event.value.id;
      this.getServices();
      this.toast.showInfo(
        "Price list is changed to " + event.value.scheduleName
      );
    }
  }

  setPriceList(priceListID) {
    const scheduleObj = this.priceList.filter((e) => {
      if (priceListID == e.id) {
        return e;
      }
    });

    if (scheduleObj.length > 0) {
      const schedule = {
        id: scheduleObj[0].id,
        scheduleName: scheduleObj[0].scheduleName,
      };

      this.selectedPriceList = schedule;
    }
  }

  get subtotals() {
    let totalService = 0;

    this.customerReceipt.invoiceServices.forEach((e) => {
      totalService = totalService + e.totalBill;
    });

    return totalService;
  }

  calculateTotals() {
    this.customerReceipt.totalBill = 0;

    this.customerReceipt.invoiceServices.forEach((e) => {
      e.totalBill = 0;
      const serviceQty = this.checkUtil.getServiceQty(e.qty,e.serviceId);

      e.itemList.forEach((item) => {
        const itemTotal =  item.itemQty * item.price + Number(item.itemDetailCharges);
        e.totalBill = e.totalBill + Number(itemTotal * serviceQty);
      });

      const totalService = e.totalBill;

      if (e.express == "express" && e.expressAmt != 0) {
        const expressAmt = (e.totalBill / 100) * Number(e.expressAmt);
        e.totalBill = e.totalBill + expressAmt;
      }

      //UPCHARGES
      e.totalBill = e.totalBill + e.upchargeCharges * serviceQty;

      //UPCHARGES PERCENTAGE
      const perAmount = (totalService / 100) * e.upchargeChargesPer;

      e.totalBill = e.totalBill + perAmount;

      let serviceDiscount = 0;

      if (e.discountMethod == "%" && e.discount != 0) {
        serviceDiscount = (e.totalBill / 100) * Number(e.discount);
      } else if (e.discountMethod == "$" && e.discount != 0) {
        serviceDiscount = Number(e.discount);
      }

      e.totalDiscountAmt = serviceDiscount;


      //TOTAL RECEIPT SERVICE
      e.totalBill = e.totalBill - serviceDiscount;

      this.customerReceipt.totalBill =
        this.customerReceipt.totalBill + e.totalBill;
    });

    const subtotal = this.customerReceipt.totalBill;

    let couponAmount = 0;

    if (this.customerReceipt.discount != 0) {
      if (this.customerReceipt.discountMethod == "%") {
        couponAmount = (subtotal / 100) * this.customerReceipt.discount;
      } else {
        couponAmount = this.customerReceipt.discount;
      }
      this.customerReceipt.totalBill = Number(
        this.customerReceipt.totalBill - couponAmount
      );
    }

    this.customerReceipt.totalAfterDisc = Number(subtotal - couponAmount);

    this.calculateTaxAmt();

    const taxAmount = this.getSummerizedTaxAmt;

    this.customerReceipt.totalBill = this.customerReceipt.totalBill + taxAmount;
  }

  calculateTaxAmt() {
    this.serviceItemList = [];

    //CALCULATING TAX EXPEMPTS
    const services = this.customerReceipt.invoiceServices;

    services.forEach((e) => {

      const itemList: TaxedItem[] = [];
      const serviceQty = this.checkUtil.getServiceQty(e.qty,e.serviceId);
      
      e.itemList.forEach((i) => {
        let newItemTotal = 0;

        //UPCHARGE IS IN PERCENTAGE
        if (e.upchargeChargesPer != 0) {
          const upAmount =
            (Number(i.price + i.itemDetailCharges) / 100) *
            Number(e.upchargeChargesPer);
          newItemTotal =
            Number(i.price + i.itemDetailCharges + upAmount) *
            i.itemQty *
           serviceQty;
        } else {
          newItemTotal =
            Number(i.price + i.itemDetailCharges) * i.itemQty * serviceQty;
        }

        const item: TaxedItem = {
          serviceID: e.id,
          itemName: i.itemName,
          orignalPrice: Number(i.price + i.itemDetailCharges),
          newPrice: Number(newItemTotal),
          itemQty: Number(i.itemQty),
          serviceQty: serviceQty,
          taxExempt: i.tax,
        };

        itemList.push(item);
      });

      //UPCHARGE IS IN DOLLAR
      if (e.upchargeCharges != 0) {
        const item: TaxedItem = {
          serviceID: e.id,
          itemName: "UpChargeItem",
          orignalPrice: Number(e.upchargeCharges),
          newPrice: Number(e.upchargeCharges) * serviceQty,
          itemQty: 1,
          serviceQty: serviceQty,
          taxExempt: "not_exempt",
        };

        itemList.push(item);
      }

      //APPLY EXPRESS
      if (e.express == "express") {
        const express = 15;
        itemList.map((i) => {
          const expressAmount =
            (Number(i.orignalPrice * i.itemQty * i.serviceQty) / 100) * express;
          i.newPrice = Number(i.newPrice + expressAmount);
        });
      }

      //APPLY SERVICE DISCOUNT %
      if (e.discountMethod == "%" && e.discount != 0) {
        itemList.map((i) => {
          const disAmount = (Number(i.newPrice) / 100) * Number(e.discount);
          i.newPrice = Number(i.newPrice - disAmount);
        });
      } else if (e.discountMethod == "$" && e.discount != 0) {
        let discountAmt = e.discount;

        //SORTING
        const items = itemList.sort((a, b) => b.newPrice - a.newPrice);

        //DEDUCTING DISCOUNT AMOUNT
        items.map((item) => {
          const itemDiscount = Math.min(item.newPrice, discountAmt);
          if (itemDiscount > 0) {
            item.newPrice -= itemDiscount;
            discountAmt -= itemDiscount;
          }
        });
      }

      itemList.map((e) => this.serviceItemList.push(e));
    });

    if (
      this.customerReceipt.discount != 0 &&
      this.customerReceipt.discountMethod == "$"
    ) {
      let receiptDisc = this.customerReceipt.discount;

      //SORT THE OVER LIST
      this.serviceItemList.sort((a, b) => b.newPrice - a.newPrice);

      //DEDUCTING THE RECEIPT DISCOUNT
      this.serviceItemList.map((item) => {
        const itemDiscount = Math.min(item.newPrice, receiptDisc);
        if (itemDiscount > 0) {
          item.newPrice -= itemDiscount;
          receiptDisc -= itemDiscount;
        }
      });
    } else if (
      this.customerReceipt.discount != 0 &&
      this.customerReceipt.discountMethod == "%"
    ) {
      const receiptDisc = this.customerReceipt.discount;

      this.serviceItemList.map((item) => {
        const disAmount = (Number(item.newPrice) / 100) * Number(receiptDisc);
        item.newPrice = Number(item.newPrice - disAmount);
      });
    }

    this.setSingleServiceTaxAmt();
  }

  closePINDialog(params) {
    this.getSetUp();
    this.customerReceipt.employeeName = params[1] + " " + params[2];
    this.customerReceipt.employeeId = params[0];
    this.toast.showSuccess("Welcome " + this.customerReceipt.employeeName);
    this.verificationType = "associate";
    this.actionType = "";
  }

  setServiceTags() {
    const index = this.receiptServices.length - 1;

    let tags = "";

    this.receiptServices[index].invoiceWavier = [];

    this.checkedTags.forEach((e) => {
      tags = tags + e.categoryName + " / ";

      if (e.categoryType == "type" && e.description != "") {
        this.receiptServices[index].invoiceWavier.push(e);
      }
    });

    this.receiptServices[index].tag1 = tags;

    tags = "";

    if (this.checkedCrease != "None") {
      tags = tags + "Crease : " + this.checkedCrease + " / ";
    }

    if (this.checkedFolding != "None") {
      tags = tags + "Folding : " + this.checkedFolding + " / ";
    }

    let chargesPercentage = 0;
    let chargesAmount = 0;

    this.checkedUpcharges.forEach((e) => {
      let symbol = "";

      if (e.optChargeValue == "percentage") {
        chargesPercentage = chargesPercentage + Number(e.chargeValue);
        symbol = "%";
      } else {
        chargesAmount = chargesAmount + Number(e.chargeValue);
        symbol = "$";
      }

      tags = tags + e.priceName + " " + e.chargeValue + " " + symbol + " / ";
    });

    this.receiptServices[index].upchargeCharges = chargesAmount;
    this.receiptServices[index].upchargeChargesPer = chargesPercentage;
    this.receiptServices[index].tag2 = tags;

    this.calculateTotals();
  }

  get servicesWaviersList() {
    const list: ColorList[] = [];

    this.receiptServices.forEach((e) => {
      list.push(...e.invoiceWavier);
    });

    return list;
  }

  get getSummerizedTax() {
    let totalTax = 0;

    if (this.customerReceipt.canadaExempt == "no") {
      totalTax = totalTax + Number(this.customerReceipt.invoiceTaxes[0].taxValue);
    }

    if (this.customerReceipt.provinceExempt == "no") {
      totalTax = totalTax + Number(this.customerReceipt.invoiceTaxes[1].taxValue);
    }

    return totalTax;
  }
  
  get getTotalTaxName() {

    let taxName = '';

    if(this.customerReceipt.invoiceTaxes.length > 0)
    {
      if (this.customerReceipt.invoiceTaxes[0].taxName != 'None' && this.customerReceipt.invoiceTaxes[0].taxName != '') {
        taxName = taxName + this.customerReceipt.invoiceTaxes[0].taxName;
      }
      
      if (this.customerReceipt.invoiceTaxes[0].taxName != 'None' && this.customerReceipt.invoiceTaxes[0].taxName != '' && this.customerReceipt.invoiceTaxes[1].taxName != 'None' && this.customerReceipt.invoiceTaxes[1].taxName != '') {
        taxName = taxName + ' + ';
      }

      if (this.customerReceipt.invoiceTaxes[1].taxName != 'None' && this.customerReceipt.invoiceTaxes[1].taxName != '') {
        taxName = taxName + this.customerReceipt.invoiceTaxes[1].taxName;
      }
    }

    return taxName;
  }

  get getSummerizedTaxAmt() {
    let totalTax = 0;
    let tax1Amt = 0;
    let tax2Amt = 0;

    if(this.customerReceipt.invoiceTaxes.length > 0)
    {
      this.serviceItemList.forEach((e) => {
        if (
          e.taxExempt != "Fd Ex" &&
          e.taxExempt != "Both Ex" &&
          this.customerReceipt.canadaExempt == "no"
        ) {
          //federal tax
          const amt =
            (Number(e.newPrice) / 100) * Number(this.customerReceipt.invoiceTaxes[0].taxValue);
          tax1Amt = tax1Amt + amt;
          totalTax = totalTax + amt;
        }

        if (
          e.taxExempt != "Prov Ex" &&
          e.taxExempt != "Both Ex" &&
          this.customerReceipt.provinceExempt == "no"
        ) {
          //provincial tax
          const amt =
            (Number(e.newPrice) / 100) * Number(this.customerReceipt.invoiceTaxes[1].taxValue);
          tax2Amt = tax2Amt + amt;
          totalTax = totalTax + amt;
        }
      });

      this.customerReceipt.invoiceTaxes[0].taxAmt = tax1Amt;
      this.customerReceipt.invoiceTaxes[1].taxAmt = tax2Amt;
    }

    return Number(totalTax);
  }
  
  get taxNamesAndCalculation() {

    let taxName = '';

    if(this.customerReceipt.invoiceTaxes.length > 0)
    {
      if(this.customerReceipt.invoiceTaxes[0].taxName != 'None' && this.customerReceipt.invoiceTaxes[0].taxName != '')
      {
        taxName = taxName + this.customerReceipt.invoiceTaxes[0].taxName + ' (' + this.customerReceipt.invoiceTaxes[0].taxValue + '%' + ') $' + this.formatAmount(this.customerReceipt.invoiceTaxes[0].taxAmt)+' ';
      }
      
      if(this.customerReceipt.invoiceTaxes[1].taxName != 'None' && this.customerReceipt.invoiceTaxes[1].taxName != '')
      {  
        taxName = taxName + this.customerReceipt.invoiceTaxes[1].taxName + ' (' + this.customerReceipt.invoiceTaxes[1].taxValue + '%' + ') $' + this.formatAmount(this.customerReceipt.invoiceTaxes[1].taxAmt)+' ';
      }

      if(this.customerReceipt.invoiceTaxes[0].taxName != 'None' && this.customerReceipt.invoiceTaxes[0].taxName != '' && this.customerReceipt.invoiceTaxes[1].taxName != 'None' && this.customerReceipt.invoiceTaxes[1].taxName != '')
      {
       taxName = taxName + this.getTotalTaxName +' ( '+this.getSummerizedTax+'% ) $'+this.formatAmount(this.getSummerizedTaxAmt);
      }
    }
    return taxName;
  }

  setSingleServiceTaxAmt() {
    //CALCULATING TAX EXPEMPTS
    const services = this.customerReceipt.invoiceServices;

    services.forEach((s) => {
      let totalTax = 0;
      this.serviceItemList.forEach((e) => {
        if (s.id == e.serviceID) {
         
          if (
            e.taxExempt != "Prov Ex" &&
            e.taxExempt != "Both Ex" &&
            this.customerReceipt.provinceExempt == "no"
          ) {
            //provincial tax
            totalTax =
              totalTax + (Number(e.newPrice) / 100) * Number(this.customerReceipt.invoiceTaxes[0].taxValue);
          }

          if (
            e.taxExempt != "Fd Ex" &&
            e.taxExempt != "Both Ex" &&
            this.customerReceipt.canadaExempt == "no"
          ) {
            //federal tax
            totalTax =
              totalTax + (Number(e.newPrice) / 100) * Number(this.customerReceipt.invoiceTaxes[1].taxValue);
          }
        }
      });
      s.tax = Number(totalTax);
    });
  }

  openPaymentMethod() {
    if (
      this.customerReceipt.dueDate != "" &&
      this.customerReceipt.dueTime != ""
    ) {
      if (this.corporateLimit <= 0 || this.subtotals <= this.corporateLimit) {
        this.paymentDialog = true;
        this.store.dispatch(
          ActionTypes.TOTAL_BILL,
          this.customerReceipt.totalBill
        );
      } else {
        this.toast.showWarning(
          "The limit for the corporate is $" + this.corporateLimit
        );
      }
    } else {
      this.toast.showInfo("Please set due date and time");
    }
  }

  closePaymentScreen() {
    this.paymentDialog = false;
  }

  setAllDetails()
  {
    this.customerReceipt.totalQty        = this.totalServices;
    this.customerReceipt.totalExpress    = this.totalExpressAmount;
    this.customerReceipt.taxCode         = this.taxNamesAndCalculation;
    this.customerReceipt.taxName         = this.getTotalTaxName;
    this.customerReceipt.subTotal        = this.subtotals;
    this.customerReceipt.invoiceWavier   = this.servicesWaviersList;
    this.customerReceipt.invoiceTaxes    = this.customerReceipt.invoiceTaxes;
    this.customerReceipt.taxAmountPrice = this.getSummerizedTaxAmt;
  }

  getProceededPayments(paymentList) {

    if (
      this.customerReceipt.dueDate != "" &&
      this.customerReceipt.dueTime != ""
    ) {
      if (this.corporateLimit <= 0 || this.subtotals <= this.corporateLimit) {
       
        this.setAllDetails();

        const totalPaid = this.checkUtil.getTotalPaid(paymentList);
        const method = this.getPaymentMethod(paymentList);
        const drawerStatus = this.shouldOpenDrawer(paymentList);

        this.customerReceipt.paymentMethod = method;
        this.customerReceipt.totalPaid = totalPaid[0];
        this.customerReceipt.totalTendered = totalPaid[1];
        this.customerReceipt.totalChange = totalPaid[2];
        this.customerReceipt.totalRound = totalPaid[3];
        this.customerReceipt.invoicePayment = paymentList;
        this.customerReceipt.currentNeedles = this.checkUtil.getCurrentNeedles(paymentList);
        this.checkTxn.sendCheckInRq(this.customerReceipt).then((res) => {
          if (res.alert == "info") {
           this.customerReceipt.employeeId = "";
           this.customerReceipt.employeeName = "";
           this.verificationType = "associate";
           this.actionType = "repon";
           this.clearReceipt();
           this.sendNotifications(res.receiptID,drawerStatus);
          }

          this.toast.handleResponse(res);
        });

        this.paymentDialog = false;
        this.corporateConfirmDialog = false;
      } else {
        this.toast.showWarning(
          "Sorry corporate limit is $" + this.corporateLimit
        );
      }
    } else {
      this.toast.showInfo("Please set due date and time");
    }
  }

  sendNotifications(receiptID,drawerStatus)
  {
     this.checkTxn.sendNotifications(receiptID,drawerStatus).then((res) => {
        this.toast.handleResponse(res);
      });
  }

  get totalExpressAmount() {
    return this.checkUtil.totalExpressAmount(this.receiptServices);
  }

  setWeekDates(dates) {
    const d = this.camelizeKeys(dates.week_dates);
    this.weekDatesList = this.checkUtil.setWeekDates(d);
  }

  get multiAxisData() {
    return this.checkUtil.multiAxisData(
      this.onTheRackLabels,
      this.capacityLabels
    );
  }

  openCustomerProfile() {
    if (this.customerReceipt.cusId != "") {
      this.dialogTitle = "Customer Profile";
      this.permission = "yes";
      this.customerStatus = "updateCustomer";
      this.newCustomerID = this.customerReceipt.cusId;

      this.customerService.getItem(this.customerReceipt.cusId).then((res) => {
        this.customerProfile = this.camelizeKeys(res);
      });
    }
  }

  deleteCoupon() {
    this.customerReceipt.discount = 0;
    this.customerReceipt.discountMethod = "";
    this.customerReceipt.discountName = "";
    this.customerReceipt.discountId = "";
    this.toast.showSuccess("Coupon removed successfully");
    this.calculateTotals();
  }

  get totalDiscountAmount() {
    return this.checkUtil.totalDiscountAmount(
      this.receiptServices,
      this.customerReceipt
    );
  }

  modifyReceipt() {
    if (
      this.customerReceipt.dueDate != "" &&
      this.customerReceipt.dueTime != ""
    ) {
      this.customerReceipt.totalQty = this.totalServices;
      this.customerReceipt.totalExpress = this.totalExpressAmount;
      this.customerReceipt.taxCode = this.taxNamesAndCalculation;
      this.customerReceipt.taxName = this.getTotalTaxName;
      this.customerReceipt.taxAmountPrice = this.getSummerizedTaxAmt;
      this.customerReceipt.subTotal = this.subtotals;
      this.customerReceipt.invoiceWavier = this.servicesWaviersList;
      this.customerReceipt.invoiceTaxes = this.customerReceipt.invoiceTaxes;

      this.checkTxn.updateCheckInRq(this.customerReceipt).then((res) => {
        if (res.alert == "info") {
          this.customerReceipt.employeeId = "";
          this.customerReceipt.employeeName = "";
          this.verificationType = "associate";
          this.actionType = "repon";
          this.clearReceipt();
        }

        this.toast.handleResponse(res);
        this.checkUtil.redirectTransaction();
      });

      this.paymentDialog = false;
      this.corporateConfirmDialog = false;
    } else {
      this.toast.showInfo("Please set due date and time");
    }
  }

  fetchReceipt() {
    this.checkTxn.getReceipt(this.receiptID).then((res) => {
      const receiptDetails = this.camelizeKeys(res);

      this.resetTags();

      //OVERALL SETTINGS
      this.customerReceipt.id = receiptDetails.invData.id;
      this.customerReceipt.timeEntrance = receiptDetails.invData.timeEntrance;
      this.customerReceipt.dueDate = receiptDetails.invData.dueDate;
      this.customerReceipt.dueTime = receiptDetails.invData.dueTime;
      this.customerReceipt.canadaExempt = receiptDetails.invData.canadaExempt;
      this.customerReceipt.provinceExempt =
        receiptDetails.invData.provinceExempt;
      this.customerReceipt.exempt1 = receiptDetails.invData.exempt1;
      this.customerReceipt.exempt2 = receiptDetails.invData.exempt2;
      this.customerReceipt.discount = receiptDetails.invData.discount;
      this.customerReceipt.discountMethod =
        receiptDetails.invData.discountMethod;
      this.customerReceipt.discountName = receiptDetails.invData.discountName;
      this.customerReceipt.discountId = receiptDetails.invData.discountId;
      this.customerReceipt.description = receiptDetails.invData.description;
      this.customerReceipt.cusId = receiptDetails.invData.cusId;
      this.customerReceipt.customer = receiptDetails.invData.customer;
      this.customerReceipt.customerTel = receiptDetails.invData.customerTel;
      this.customerReceipt.customerEmail = receiptDetails.invData.customerEmail;
      this.customerReceipt.taxCode = receiptDetails.invData.taxCode;
      this.customerReceipt.branchCode = receiptDetails.invData.branchCode;
      this.customerReceipt.telephone = receiptDetails.invData.telephone;
      this.customerReceipt.branchName = receiptDetails.invData.branchName;
      this.customerReceipt.taxNo = receiptDetails.invData.taxNo;
      this.customerReceipt.address = receiptDetails.invData.address;
      this.customerReceipt.customerNeedles = receiptDetails.invData.customerNeedles; 
      this.customerReceipt.currentNeedles = receiptDetails.invData.currentNeedles;
      this.customerReceipt.totalBill = receiptDetails.invData.totalBill;
      this.customerReceipt.totalPaid = receiptDetails.invData.totalPaid;
      this.customerReceipt.totalExpress = receiptDetails.invData.totalExpress;
      this.customerReceipt.totalQty = receiptDetails.invData.totalQty;
      this.customerReceipt.storeId = receiptDetails.invData.storeId;
      this.customerReceipt.priceListId = receiptDetails.invData.priceListId;
      this.customerReceipt.accountType = receiptDetails.invData.accountType;
      this.customerReceipt.referalOrCorp = receiptDetails.invData.referalOrCorp;
      this.customerReceipt.referalOrCorpId =
        receiptDetails.invData.referalOrCorpId;
      this.customerReceipt.referralOrCorpName =
        receiptDetails.invData.referralOrCorpName;
      this.customerReceipt.corpEmp = receiptDetails.invData.corpEmp;
      this.customerReceipt.corpPo = receiptDetails.invData.corpPo;
      this.customerReceipt.corpReq = receiptDetails.invData.corpReq;
      this.customerReceipt.corporateMeta = receiptDetails.invData.corporateMeta;
      this.customerReceipt.status = receiptDetails.invData.status;
      this.customerReceipt.taxName = receiptDetails.invData.taxName;
      this.customerReceipt.paymentMethod = receiptDetails.invData.paymentMethod;
      this.customerReceipt.totalTendered = receiptDetails.invData.totalTendered;
      this.customerReceipt.totalRound = receiptDetails.invData.totalRound;
      this.customerReceipt.totalChange = receiptDetails.invData.totalChange;
      this.customerReceipt.totalDiscount = receiptDetails.invData.totalDiscount;

      this.selectedCustomer = receiptDetails.invData.customer;

      this.setPriceList(this.customerReceipt.priceListId);
      this.getServices();

      const services: ReceiptServices[] =
        receiptDetails.invData.invoiceServices;

      services.forEach((e) => {
        this.receiptServices.push(e);
      });

      //CALCULATE TAX
      this.calculateTotals();
    });
  }
}
