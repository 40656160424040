
import { Options, Vue } from "vue-class-component";
import CorporateHead from "../../service/CorporateHead";
import Toaster from "../../helpers/Toaster";

@Options({
  components: {},
})
export default class CorporatesHead extends Vue {
  private imagePath = "";
  private lists = [];
  private corporatehead;
  private productStatus = "";
  private keyword = "";
  private productDialog = false;
  private checkPagination = true;
  private statusDialog = false;
  private totalRecords = 0;
  private limit = 0;
  private home = { icon: "pi pi-home", to: "/" };
  private items = [
    { label: "Initialization", to: "initialization" },
    { label: "Corporates Head" },
  ];
  private toast;
  private product = {
    id: 0,
    name: "",
    address: "",
    phone: "",
    email: "",
    description: "",
    status: "",
  };

  private dialogTitle;
  private dialogCallback;
  private submitted = false;

  //CALLING WHEN PAGINATION BUTTON CLICKS
  onPage(event) {
    this.loadList(event.first);
  }
  // CALLNING AFTER CONSTRUCTOR GET CALLED
  mounted() {
    this.loadList(0);
  }

  created() {
    this.corporatehead = new CorporateHead();
    this.imagePath = this.corporatehead.getBaseURL() + "uploads/services/";
    this.toast = new Toaster();
  }

  //OPEN DIALOG TO ADD NEW ITEM
  openDialog() {
    this.product = {
      id: 0,
      name: "",
      address: "",
      phone: "",
      email: "",
      description: "",
      status: "",
    };

    this.submitted = false;
    this.dialogTitle = "Add New Corporates Head";
    this.productDialog = true;
  }

  //CLOSE THE ITEM DAILOG BOX
  hideDialog() {
    this.productDialog = false;
    this.submitted = false;
  }

  //ADD OR UPDATE THE ITEM VIA HTTP
  saveItem(e) {
    e.preventDefault();

    this.submitted = true;
    if (this.product.name.trim()) {
      if (this.product.id != 0) {
        this.corporatehead.updateItem(this.product).then((res) => {
          this.loadList(0);
          this.toast.handleResponse(res);
        });
      } else {
        this.corporatehead.saveItem(this.product).then((res) => {
          this.loadList(0);
          this.toast.handleResponse(res);
        });
      }

      this.productDialog = false;
      this.product = {
        id: 0,
        name: "",
        address: "",
        phone: "",
        email: "",
        description: "",
        status: "",
      };
    }
  }

  //OPEN DIALOG BOX TO EDIT
  editIem(data) {
    this.submitted = false;
    this.dialogTitle = "Update Corporate Head";
    this.productDialog = true;

    this.corporatehead.getItem(data).then((res) => {
      if (res.length > 0) {
        this.product = {
          id: res[0].corporate_id,
          name: res[0].corporate_name,
          address: res[0].corporate_address,
          phone: res[0].corporate_phone,
          email: res[0].corporate_email,
          description: res[0].corporate_description,
          status: res[0].corporate_status,
        };
      }
    });
  }

  //OPEN DIALOG BOX FOR CONFIRMATION
  confirmChangeStatus(data, status) {
    this.product = {
      id: data.corporate_id,
      name: data.corporate_name,
      address: data.corporate_address,
      phone: data.corporate_phone,
      email: data.corporate_email,
      description: data.corporate_description,
      status: data.corporate_status,
    };
    this.productStatus = status;
    this.statusDialog = true;
  }

  //CHANGE THE STATUS AND SEND HTTP TO SERVER
  changeStatus() {
    this.statusDialog = false;
    this.product.status = this.productStatus;
    this.corporatehead.changeStatus(this.product).then((res) => {
      this.loadList(0);
      this.toast.handleResponse(res);
    });
  }

  //FETCH THE DATA FROM SERVER
  loadList(page) {
    this.corporatehead.getItems(page).then((data) => {
      this.lists = data.corporate_record;
      this.totalRecords = data.total_head_count;
      this.limit = data.limit_per_page;
    });
  }

  loadSearchData() {
    this.submitted = true;
    if (this.keyword) {
      this.corporatehead.getSearchedCorpHead(this.keyword).then((data) => {
        this.lists = data.corporate_record;
        this.checkPagination = data.pagination;
      });
    }
  }
}
